<template>
  <div class="bg-ms-white">
    <ClerkLoaded>
      <RouterView />
    </ClerkLoaded>
    <CustomNotification />
  </div>
</template>

<script setup>
import CustomNotification from '@/components/CustomNotification.vue';
import { setLanguage } from '@/services/language.service';

import { ClerkLoaded } from 'vue-clerk'

setLanguage('de');
</script>

