const Login = () => import('@/views/LoginView.vue');
const LoadingView = () => import('@/views/LoadingView.vue');
const Dashboard = () => import('@/views/Dashboard.vue');
const DashboardUserDetail = () => import('@/views/DashboardUserDetail.vue');
const DashboardCompanyDetail = () => import('@/views/DashboardCompanyDetail.vue');
const UserProfileView = () => import('@/views/profiles/UserProfileView.vue');
const UserProfileEdit = () => import('@/views/profiles/UserProfileEdit.vue');
const UserProfileChangePassword = () => import('@/views/profiles/UserProfileChangePassword.vue');
const ForgotPassword = () => import('@/views/ForgotPassword.vue');
const PasswordReset = () => import('@/views/PasswordResetView.vue');
const Register = () => import('@/views/RegisterView.vue');
const RegisterSuccess = () => import('@/views/RegisterSuccessView.vue');
const Feedback = () => import('@/views/FeedbackView.vue');
const CompanyProfileView = () => import('@/views/profiles/CompanyProfileView.vue');
const CompanyProfileEdit = () => import('@/views/profiles/CompanyProfileEdit.vue');
const Favorites = () => import('@/views/FavoritesView.vue');
const Search = () => import('@/views/SearchView.vue');
const Home = () => import('@/views/HomeView.vue');
const VerifySignupRequest = () => import('@/views/VerifySignupRequest.vue');
const EditSignupRequest = () => import('@/views/EditSignupRequest.vue');
const OfferDetail = () => import('@/views/OfferDetail.vue');
const OfferNotes = () => import('@/views/OfferNotes.vue');
const Offers = () => import('@/views/OffersView.vue');
const CreateCampaign = () => import('@/views/CreateCampaignView.vue');
const EditCampaign = () => import('@/views/EditCampaignView.vue');
const CampaignDetailView = () => import('@/views/CampaignDetailView.vue');
const CreateOffer = () => import('@/views/CreateOfferView.vue');
const EditOffer = () => import('@/views/EditOfferView.vue');
const Buyers = () => import('@/views/BuyersView.vue');
const Suppliers = () => import('@/views/SuppliersView.vue');
const Marketplace = () => import('@/views/MarketplaceView.vue');
const CreateMemberView = () => import('@/views/CreateMemberView.vue');
const CompanyProfileChangeRole = () => import('@/views/profiles/CompanyProfileChangeRole.vue');
const MarketAreaBuyers = () => import('@/views/MarketAreaBuyersView.vue');
const DelegateContacts = () => import('@/views/DelegateContactsView.vue');
const WelcomeView = () => import('@/views/welcome/WelcomeView.vue');
const WelcomeEditProfile = () => import('@/views/welcome/WelcomeEditProfile.vue');
const WelcomeEditCompany = () => import('@/views/welcome/WelcomeEditCompany.vue');

export default [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      permittedWithClerk: true,
    },
  },
  {
    path: '/accounts/signup',
    redirect: () => {
      return 'register';
    },
  },
  {
    path: '/register/success',
    name: 'register-success',
    component: RegisterSuccess,
    meta: {
      permittedWithClerk: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Home', link: '/home' }, { name: 'Dashboard' }],
    },
  },
  {
    path: '/dashboard/:handle',
    name: 'dashboard-user-detail',
    component: DashboardUserDetail,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Dashboard', link: '/dashboard' },
        { name: 'Handle' }
      ],
    },
  },
  {
    path: '/dashboard/company/:handle',
    name: 'dashboard-company-detail',
    component: DashboardCompanyDetail,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Dashboard', link: '/dashboard' },
        { name: 'Handle' }
      ],
    },
  },
  {
    path: '/loading',
    name: 'loading',
    component: LoadingView,
  },
  {
    path: '/accounts/myprofile',
    name: 'profile',
    component: UserProfileView,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Home', link: '/home' }, { name: 'My profile' }],
    },
  },
  {
    path: '/accounts/myprofile/update',
    name: 'user-profile-edit',
    component: UserProfileEdit,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'My profile', link: '/accounts/myprofile' },
        { name: 'Edit' },
      ],
    },
  },
  {
    path: '/accounts/myprofile/change-password',
    name: 'user-profile-change-password',
    component: UserProfileChangePassword,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'My profile', link: '/accounts/myprofile' },
        { name: 'Change password' },
      ],
    },
  },
  {
    path: '/accounts/profile/:id',
    name: 'accounts-profile',
    component: UserProfileView,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Home', link: '/home' }, { name: 'Profile' }],
    },
  },
  {
    path: '/accounts/profile/:id/edit',
    name: 'accounts-profile-edit',
    component: UserProfileEdit,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Profile', link: '' },
        { name: 'Edit' },
      ],
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: PasswordReset,
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: Feedback,
    meta: {
      breadcrumb: [{ name: 'Home', link: '/home' }, { name: 'Feedback' }],
    },
  },
  {
    path: '/marketplace/:targetGroup?',
    name: 'marketplace',
    component: Marketplace,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Home', link: '/home' }, { name: 'Marketplace' }],
    },
    children: [
      {
        path: 'campaign/:id',
        name: 'campaign-detail',
        component: CampaignDetailView,
      },
    ],
  },
  {
    path: '/marketplace/campaign/:id/update',
    name: 'campaign-edit',
    component: EditCampaign,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Marketplace', link: '/marketplace' },
        { name: 'Campaign', link: '' },
        { name: 'Edit' },
      ],
    },
  },
  {
    path: '/marketplace/campaign/create',
    name: 'campaign-create',
    component: CreateCampaign,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Marketplace', link: '/marketplace' },
        { name: 'Create' },
      ],
    },
  },
  {
    path: '/companies/:id/detail',
    name: 'company-profile',
    component: CompanyProfileView,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Companies', link: '' },
        { name: 'Filter', link: '' },
        { name: 'Company' },
      ],
    },
  },
  {
    path: '/companies/:id/update',
    name: 'company-profile-edit',
    component: CompanyProfileEdit,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Company', link: '' },
        { name: 'Update' },
      ],
    },
  },
  {
    path: '/companies/:id/role',
    name: 'company-profile-change-role',
    component: CompanyProfileChangeRole,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Company', link: '' },
        { name: 'Role' },
      ],
    },
  },
  {
    path: '/companies/:id/add-member',
    name: 'company-profile-add-member',
    component: CreateMemberView,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Company', link: '' },
        { name: 'Add member' },
      ],
    },
  },
  {
    path: '/companies/suppliers/:handle',
    name: 'supplier-list',
    component: Suppliers,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Suppliers', link: '/companies/suppliers/all' },
        { name: 'Handle' },
      ],
    },
  },
  {
    path: '/companies/buyers',
    name: 'buyer-list',
    component: Buyers,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Home', link: '/home' }, { name: 'Buyers' }],
    },
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Home' }],
    },
  },
  {
    path: '/:id/verify',
    name: 'verify-request',
    component: VerifySignupRequest,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:id/edit',
    name: 'edit-request',
    component: EditSignupRequest,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/offers/:handle',
    name: 'offer-list',
    component: Offers,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Offers', link: '/offers/all' },
        { name: 'Handle' }
      ],
    },
  },
  {
    path: '/offers/:id/detail/',
    name: 'offer-detail',
    component: OfferDetail,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Offers', link: '/offers/all' },
        { name: 'Filter', link: '' },
        { name: 'Offer' },
      ],
    },
  },
  {
    path: '/offers/:id/detail/notes',
    name: 'offer-notes',
    component: OfferNotes,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Offers', link: '/offers/all' },
        { name: 'Filter', link: '' },
        { name: 'Offer', link: '' },
        { name: 'Notes' },
      ],
    },
  },
  {
    path: '/offers/:id/edit',
    name: 'offer-edit',
    component: EditOffer,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Offers', link: '/offers/all' },
        { name: 'Offer', link: '' },
        { name: 'Edit' },
      ],
    },
  },
  {
    path: '/offers/create/offer',
    name: 'offer-create',
    component: CreateOffer,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'Offers', link: '/offers/all' },
        { name: 'Create' },
      ],
    },
  },
  {
    path: '/liked/',
    name: 'favorites',
    component: Favorites,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Home', link: '/home' }, { name: 'Favorites' }],
    },
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Home', link: '/home' }, { name: 'Search' }],
    },
  },
  {
    path: '/my-buyers/:handle',
    name: 'area-buyers',
    component: MarketAreaBuyers,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'My buyers', link: '/my-buyers/all' },
        { name: 'Handle' }
      ],
    },
  },
  {
    path: '/my-buyers/delegate-contacts',
    name: 'delegate-contacts',
    component: DelegateContacts,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Home', link: '/home' },
        { name: 'My buyers', link: '/my-buyers/all' },
        { name: 'Delegate contacts' },
      ],
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: WelcomeView,
    meta: {
      requiresAuth: true,
      permittedOnFirstLogin: true,
      breadcrumb: [{ name: 'Welcome' }],
    },
  },
  {
    path: '/welcome/profile/update',
    name: 'welcome-profile',
    component: WelcomeEditProfile,
    meta: {
      requiresAuth: true,
      permittedOnFirstLogin: true,
      breadcrumb: [
        { name: 'Home', link: '/Home' },
        { name: 'Profile', link: '/welcome/profile' },
        { name: 'Edit' },
      ],
    },
  },
  {
    path: '/welcome/company/update',
    name: 'welcome-company',
    component: WelcomeEditCompany,
    meta: {
      requiresAuth: true,
      permittedOnFirstLogin: true,
      breadcrumb: [
        { name: 'Welcome', link: '/welcome' },
        { name: 'Company', link: '/welcome/company' },
        { name: 'Edit' },
      ],
    },
  },
];
