import { nextTick } from 'vue';

const scrollToAnchor = {
  mounted: (el, binding) => {
    nextTick(() => {
      if (el.id === binding.value.replace('#', '')) {
        const element = document.querySelector(`#${el.id}`);
        const header = document.querySelector(`.header`);
        window.scrollTo({
          behavior: 'smooth',
          top:
            element.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            header.getBoundingClientRect().height,
        });
      }
    });
  },
};

export default scrollToAnchor;
