import axios from 'axios';
import router from '../router';
import { parseToCamel } from '@/helpers/transformCase';

const delete_cookie = (name) => {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  // Because the new website is running on the same domain as the old one,
  // it is possible that we still have old cookies/tokens which no longer work.
  // If this happens, we need to clean them up, so the new ones are used.
  if (parts.length >= 3) {
    delete_cookie('csrftoken');
    delete_cookie('sessionid');
    sessionStorage.clear();
  }
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const loginInterceptor = (config) => {
  // As the api for live and the frontend for staging are both running on
  // subdomains, we need to postfix the cookie, so each subdomain takes the
  // proper cookie and we do not mix them up
  let postfix = '';
  if (`${import.meta.env.VITE_API_COOKIE_POSTFIX}` !== "undefined") {
    postfix = `${import.meta.env.VITE_API_COOKIE_POSTFIX}`;
  }
  config.headers['X-CSRFToken'] = getCookie('csrftoken' + postfix);

  if (getCookie('__session')) {
    const token = getCookie('__session')
    config.headers['Authorization'] = `Bearer ${token}`;
  } 
  
  return config;
};

const userApi = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
  withCredentials: true,
});

const userApiUnprotected = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}unprotected`,
  withCredentials: true,
});

userApi.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // No special error handling needed for cancelled requests (as used for typeahead)
    if (error.code === 'ERR_CANCELED') {
      return Promise.reject(error);
    }
    // Return any error which is not due to authentication back to the calling service
    if ((error.response && error.response.status !== 401) || !router.app._route.meta.requiresAuth) {
      return Promise.reject(parseToCamel(error));
    }

    router.push({ name: 'login' });
  }
);

userApiUnprotected.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if ((error.response && error.response.status !== 401) || !router.app._route.meta.requiresAuth) {
      return Promise.reject(parseToCamel(error));
    }

    router.push({ name: 'login' });
  }
);

userApi.interceptors.request.use(loginInterceptor);

export { userApi, userApiUnprotected };
