import { createWebHistory, createRouter } from 'vue-router';
import { useAuthStore } from '@/store/Auth';
import { getCurrentUser } from '@/services/auth';
import routes from './routes.js';

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

let auth;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      const metaNavHeight = document.querySelector('.meta-nav') ? document.querySelector('.meta-nav').offsetHeight : 0;
      const topNavHeight = document.querySelector('header') ? document.querySelector('header').offsetHeight : 0;
      return { 
        el: to.hash,
        behavior: 'smooth', 
        top: metaNavHeight + topNavHeight};
    }
    return { top: 0 };
  },
});

/**
 * Check if the user is logged in
 *
 * @param {object} to The route to navigate to
 */
const checkUserLoggedIn = (to) => {
  return !to.meta.requiresAuth || auth.isLoggedIn;
};

/**
 * Check the permissions of a user and route
 *
 * @param {object} to The route to navigate to
 */
const checkUserPermissions = (to) => {
  const authStore = useAuthStore();
  
  if(!authStore.isLoggedIn) {
    return true
  }

  if (auth.isFirstLoginCompleted && to.meta.permittedOnFirstLogin) {
    return false;
  }
  return true;
};

/**
 * Check if first login process is completed
 *
 * @param {object} to The route to navigate to
 */
const checkFirstLoginCompleted = (to) => {
  if (auth.isLoggedIn && !to.meta.permittedOnFirstLogin && !auth.isFirstLoginCompleted) {
    return false;
  }
  return true;
};

const checkFirstClerkLoginCompleted = (to) => {
  if (!auth.isLoggedIn && !!getCookie('__session') && !to.meta.permittedWithClerk && to.meta.requiresAuth) {
    return false;
  }
  return true;
};

router.beforeEach(async (to, from, next) => {
  const goToPage = (routeName) => {
    return next({ name: routeName });
  };

  if (!auth) auth = useAuthStore();

  try {
    const user = await getCurrentUser();
    auth.setUser(user);
  } catch (e) {
    auth.removeAuth();
  }

  if (!checkUserLoggedIn(to) && !getCookie('__session')) {
    return goToPage('login');
  }

  if (to.name === 'login' && auth.isLoggedIn) {
    return goToPage('home');
  }

  if (!checkFirstClerkLoginCompleted(to)) {
    return goToPage('register');
  }

  if (!checkFirstLoginCompleted(to)) {
    return goToPage('welcome');
  }

  if (!checkUserPermissions(to)) {
    return goToPage('home');
  }

  // Prevent KAM from entering campaign create/edit routes
  if ((to.name === 'campaign-create' || to.name === 'campaign-edit') && auth.isKam) {
    return goToPage('home');
  }

  return next();
});

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location = to.fullPath;
  }
});

export default router;
