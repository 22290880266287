<template>
  <notifications position="top" class="notification-position">
    <template #body="props">
      <div class="notification" :class="getClass(props.item.type)">
        <SvgIcon
          :name="getIcon(props.item.type)"
          class="mr-3 mt-1 svg-icon--lg min-w-[1.5rem]"
          :class="getColorClass(props.item.type)"
        />
        <div class="flex-1">
          <span v-if="props.item.text" class="font-bold" :class="getColorClass(props.item.type)">
            {{ props.item.text }}
          </span>
          <slot></slot>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script setup>
const ERRORTYPE = 'error';

const getIcon = (type) => {
  if (type === ERRORTYPE) return 'warning-alert';
  return type === 'success' ? 'success-alert' : 'information-alert';
};

const getClass = (type) => {
  if (type === ERRORTYPE) return 'is-warning';
  return type === 'success' ? 'is-success' : 'is-info';
};

const getColorClass = (type) => {
  if (type === ERRORTYPE) return 'is-warning';
  return type === 'success' ? 'is-success' : 'is-info';
};
</script>
<style lang="postcss" scoped>
.notification-position {
  @apply !w-[792px] !top-28;
  left: calc(50% - 396px);
  @media only screen and (max-width: 1023px) {
    @apply !w-auto left-0 right-0 !top-16;
  }
}
</style>
