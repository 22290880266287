// Transform every key of an object and all child
function transformAllKeys(obj, transformFunction) {
  if (Array.isArray(obj)) {
    // Apply transformation to all children of the array
    obj = obj.map((item) => transformAllKeys(item, transformFunction));
  } else if (typeof obj === 'object' && obj) {
    const outObj = {};

    Object.keys(obj).forEach((key) => {
      // Transform the key and transform all keys of any child objects
      outObj[transformFunction(key)] = transformAllKeys(obj[key], transformFunction);
    });

    return outObj;
  }
  return obj;
}

// Converts a string from snake_case to camelCase
export function snakeToCamel(str) {
  const parts = str.split('_');

  // Capitalize every parts but the first one
  const capitalizedParts = parts.slice(1).map((part) => {
    return part.charAt(0).toUpperCase() + part.slice(1);
  });

  return parts[0] + capitalizedParts.join('');
}

// Converts a string from camelCase to snake_case
export function camelToSnake(str) {
  // Matches any capital letter and any following non capital characters
  // Example: camelCaseString -> camel, Case, String
  let parts = str.split(/([A-Z0-9].*?(?=[A-Z0-9]|$))/);
  // Remove empty parts
  parts = parts.filter(part => part !== '')

  return parts.join('_').toLowerCase('');
}

export function parseToCamel(obj) {
  return transformAllKeys(obj, snakeToCamel);
}

export function parseToSnake(obj) {
  return transformAllKeys(obj, camelToSnake);
}
