import { defineStore } from 'pinia';

export const useAuthStore = defineStore('Auth', {
  state: () => {
    return {
      /**
       * hydrate the state from the session if it exists otherwise null it
       */
      id: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      email: sessionStorage.getItem('email') || null,
      profilePic: null,
      profileType: null,
      companyId: null,
      kam: null,
      groups: [],
      loginCompleted: false,
    };
  },
  getters: {
    /**
     * Whether the user is logged in
     */
    isLoggedIn: (state) => state.email !== null,
    isAdmin: (state) => state.groups.includes('Dashboard'),
    isKam: (state) => state.profileType === 'kam',
    isDmc: (state) => state.profileType === 'dmc',
    isBuyer: (state) => state.profileType === 'buyer',
    isSupplier: (state) => state.profileType === 'supplier',
    isCompanyMember: (state) => {
      return (companyId) => state.companyId === parseInt(companyId);
    },
    isOwnUserProfile: (state) => {
      return (userId) => state.id === parseInt(userId) || !userId;
    },
    getUserId: (state) => state.id,
    getCompanyId: (state) => state.companyId,
    getKamId: (state) => state.kam?.id,
    isFirstLoginCompleted: (state) => state.loginCompleted === true,
  },
  actions: {
    /**
     * Remove the login information and effectively log the user out
     */
    removeAuth() {
      this.id = null;
      this.firstName = null;
      this.lastName = null;
      this.phoneNumber = null;
      this.email = null;
      this.profilePic = null;
      this.profileType = null;
      this.companyId = null;
      (this.kam = null), (this.groups = []);
      sessionStorage.clear();
    },
    setUser(user) {
      this.id = user.id;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.email = user.email;
      this.profilePic = user.profilePic;
      this.phoneNumber = user.phoneNumber;
      this.profileType = user.profileType;
      this.companyId = user.companyId;
      this.kam = user.kam;
      this.groups = user.groups;
      this.loginCompleted = user.loginCompleted;
      sessionStorage.setItem('email', this.email);
      sessionStorage.setItem('profileType', this.profileType);
      sessionStorage.setItem('groups', this.groups);
    },
  },
});
