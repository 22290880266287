import { userApi, userApiUnprotected } from './api';
import { parseToSnake, parseToCamel } from '@/helpers/transformCase';

/**
 * Perform a refresh
 *
 * @return User Info
 */

const createSignupRequest = async (payload) => {
  if (!payload.areaId) {
    // Set areaId to null if value is not set, so backend can parse it.
    payload.areaId = null;
  }
  const parsedPayload = parseToSnake(payload);
  const response = await userApiUnprotected.post('account/sign-up-request', parsedPayload);
  return response.data;
};

const signupRequestExists = async (email) => {
  const response = await userApiUnprotected.get('account/sign-up-request/exists', { params: { email: email } });
  return response.data;
};

const update = async (id, firstName, lastName, phoneNumber) => {
  const response = await userApi.put(`account/profile`, {
    firstName: firstName,
    lastName: lastName,
    phoneNumber: phoneNumber,
  });
  return response.data;
};

const updatePicture = async (id, profilePic) => {
  const payload = {
    profilePic: profilePic,
  };
  const fd = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    if (value) fd.append(key, value);
    else fd.append(key, '');
  });
  const response = await userApi.put(`account/users/picture/${id}/`, fd);
  return response.data;
};

const forgotPassword = async (email) => {
  const response = await userApiUnprotected.post('account/reset-password/email', {
    email: email,
  });

  return response.data;
};

const checkResetToken = async (token, email) => {
  const response = await userApiUnprotected.post('account/reset-password/check-token', {
    token: token,
    email: email,
  });
  return response.data;
};

const resetPassword = async (password, token, email) => {
  try {
    const response = await userApiUnprotected.post('account/reset-password/reset', {
      password: password,
      token: token,
      email: email,
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 400) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};

const footer = async () => {
  const response = await userApiUnprotected.get('/cms/footer?event_result_count=3');
  return parseToCamel(response.data);
};

const getCurrentUser = async () => {
  const response = await userApi.get('account/refresh-csrf-token');
  return parseToCamel(response.data);
};

const getProfileTypes = async () => {
  const response = await userApiUnprotected.get('account/profile_types');
  return response.data;
};

const getMarketAreas = async () => {
  const response = await userApiUnprotected.get('companies/areas');
  return response.data;
};

const getCompanyCategories = async () => {
  const response = await userApiUnprotected.get('companies/categories');
  return response.data;
};


export {
  createSignupRequest,
  forgotPassword,
  checkResetToken,
  resetPassword,
  update,
  footer,
  updatePicture,
  getCurrentUser,
  getProfileTypes,
  getMarketAreas,
  getCompanyCategories,
  signupRequestExists
};
